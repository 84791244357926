import React, { useState } from "react";
import "../StyleSheets/zma.css";
import logo from "../Assets/img/medlogo.png";
import bannerImage from "../Assets/img/bannerImg.png";
import Footer from "./Common/Footer";
import serviceMedqImg from "../Assets/service-mediq-img.webp";
import serviceZongImg from "../Assets/service-zong-img.webp";
import listCheckImg from "../Assets/list-check-img.webp";
import { Modal } from "react-bootstrap";
import modalRightImg from "../Assets/modal-right-img.webp";
import appleStoreImg from "../Assets/apple-store-img.webp";
import googleStoreImg from "../Assets/google-store-img.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

type props = {
  validateSubscribe: (_packageName: string) => Promise<void>;
  mainLoader: boolean;
  isLoader: boolean;
  onModalPress: () => void;
  showModal: boolean;
  userSubscribed: boolean;
  errorMsg: string;
  onUnSubscribePress: () => Promise<void>;
};

const LandingMzaPage = (props: props) => {
  return (
    <>
      {/* {/ Modal-start /} */}
      {props.mainLoader && (
        <div className="medic-loader">
          <span className="loader"></span>
        </div>
      )}
      <Modal
        show={props.showModal}
        onHide={props.onModalPress}
        className="medic-modal"
        size="lg"
      >
        <Modal.Body>
          <div className="modal-content">
            <div className="row">
              <div className="col-lg-8 col-12">
                <div className="modal-text">
                  <div className="heading">GET THE MEDIQ APP</div>
                  <div className="content">
                    Get unique health discounts and easy access to first-rate
                    medical services by downloading the MedIQ app right now.
                  </div>
                  <div className="modal-app-button">
                    <a
                      href=" https://apps.apple.com/us/app/mediq-smart-healthcare/id1510914142?ls=1"
                      target="_blank"
                    >
                      <img src={appleStoreImg} alt="" />
                    </a>
                    <a
                      href="https://play.google.com/store/search?q=mediq+smart+healthcare&c=apps"
                      target="_blank"
                    >
                      <img src={googleStoreImg} alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="modal-right-img">
                  <img src={modalRightImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          <button type="button" aria-label="Close" onClick={props.onModalPress}>
            {" "}
            Close
          </button>
        </Modal.Header>
      </Modal>
      {/* {/ Modal-end /} */}
      <div className="navbarWraper">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="medLogo">
                <img src={logo} width={140} height={60} alt="logo" />
              </div>
            </div>
            <div className="col-6">
              <div className="navLinks">
                <ul>
                  <li>
                    {props.userSubscribed ? (
                      <>
                        {props.isLoader ? (
                          <a className="subscribe">UnSubscribing...</a>
                        ) : (
                          <a
                            className="subscribe"
                            onClick={props.onUnSubscribePress}
                          >
                            UnSubscribe
                          </a>
                        )}
                      </>
                    ) : (
                      <a className="subscribe" href="#zma__section">
                        Subscribe
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bannerSec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="bannerImg">
                {" "}
                <img src={bannerImage} alt="banner" />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="bannerHead">
                <h1>MedIQ </h1>
              </div>
              <div className="sub-heading">
                <h1>Smart Healthcare</h1>
              </div>
              <div className="bannerText">
                <p>
                  is redefining the healthcare industry and delivering
                  unparalleled service to its customers. Through medIQ app, you
                  can benefit from the one-stop-shop solution for all your
                  healthcare needs. The platform is committed to making quality
                  healthcare services affordable, accessible, convenient,
                  efficient, and cost-effective{" "}
                </p>
              </div>
              <div className="bannerBtn" onClick={props.onModalPress}>
                <a className="contactBtn">
                  Download App
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.24408 5.74408C8.56951 5.41864 9.09715 5.41864 9.42259 5.74408L13.5893 9.91074C13.9147 10.2362 13.9147 10.7638 13.5893 11.0893L9.42259 15.2559C9.09715 15.5814 8.56951 15.5814 8.24408 15.2559C7.91864 14.9305 7.91864 14.4028 8.24408 14.0774L11.8215 10.5L8.24408 6.92259C7.91864 6.59715 7.91864 6.06951 8.24408 5.74408Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="zma-page main-padding" id="zma__section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page__heading">MedIQ Service for Zong users</div>
            </div>
            <div className="col-md-6 col-12">
              <div className="services-left-content">
                <div className="service-top-content">
                  <div className="service-img">
                    <img
                      src={serviceMedqImg}
                      alt="Mediq-image"
                      width={184}
                      height={115}
                    />
                  </div>
                  <span>/</span>
                  <div className="service-img">
                    <img src={serviceZongImg} alt="Zong-image" />
                  </div>
                </div>
                <div className="service-list-content">
                  <div className="service-charges">
                    <div className="ser_text">Health (Sehat)</div>
                    <div className="ser_price">Rs.05+ Tax</div>
                  </div>
                  <ul>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Tele/video consultation with general practitioners 24/7
                        (Free Unlimited)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Tele / video consultation with Specialists (32
                        Specialties) (50% Discount)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Online lab services (20% Discount)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Online pharmacy services (8% Discount)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Electronic Medical Record</div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Video on Demand</div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Health Tips</div>
                    </li>
                  </ul>
                  {props.userSubscribed ? (
                    <div className="bannerBtn" onClick={props.onModalPress}>
                      <a
                        // target="_blank"
                        className="contactBtn"
                      >
                        Download App
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.24408 5.74408C8.56951 5.41864 9.09715 5.41864 9.42259 5.74408L13.5893 9.91074C13.9147 10.2362 13.9147 10.7638 13.5893 11.0893L9.42259 15.2559C9.09715 15.5814 8.56951 15.5814 8.24408 15.2559C7.91864 14.9305 7.91864 14.4028 8.24408 14.0774L11.8215 10.5L8.24408 6.92259C7.91864 6.59715 7.91864 6.06951 8.24408 5.74408Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </div>
                  ) : (
                    <>
                      {props.isLoader ? (
                        <div className="bannerBtn">
                          <button className="contactBtn">Subscribing...</button>
                        </div>
                      ) : (
                        <div className="bannerBtn">
                          <button
                            className="contactBtn"
                            onClick={() => props.validateSubscribe("ms")}
                          >
                            Subscribe{" "}
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="chev-right"
                            />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="services-left-content">
                <div className="service-top-content">
                  <div className="service-img">
                    <img
                      src={serviceMedqImg}
                      alt="Mediq-image"
                      width={184}
                      height={115}
                    />
                  </div>
                  <span>/</span>
                  <div className="service-img">
                    <img src={serviceZongImg} alt="Zong-image" />
                  </div>
                </div>
                <div className="service-list-content">
                  <div className="service-charges">
                    <div className="ser_text">Life Tahafuzz</div>
                    <div className="ser_price">Rs.08+ Tax</div>
                  </div>
                  <ul>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Tele/video consultation with general practitioners 24/7
                        (Free Unlimited)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Tele / video consultation with Specialists (32
                        Specialties) (50% Discount)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Online lab services (20% Discount)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Online pharmacy services (8% Discount)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Electronic Medical Record</div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Video on Demand</div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Health Tips</div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Hospitalization cover (PKR 2,000 per night up to 30
                        nights in a year)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">
                        Accidental coverage (PKR 50,000)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src={listCheckImg} alt="" />
                      </div>
                      <div className="i-text">Life Insurance (PKR 250,000)</div>
                    </li>
                  </ul>
                  {props.userSubscribed ? (
                    <div className="bannerBtn" onClick={props.onModalPress}>
                      <a className="contactBtn">
                        Download App
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.24408 5.74408C8.56951 5.41864 9.09715 5.41864 9.42259 5.74408L13.5893 9.91074C13.9147 10.2362 13.9147 10.7638 13.5893 11.0893L9.42259 15.2559C9.09715 15.5814 8.56951 15.5814 8.24408 15.2559C7.91864 14.9305 7.91864 14.4028 8.24408 14.0774L11.8215 10.5L8.24408 6.92259C7.91864 6.59715 7.91864 6.06951 8.24408 5.74408Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </div>
                  ) : (
                    <>
                      {props.isLoader ? (
                        <div className="bannerBtn">
                          <button className="contactBtn">Subscribing...</button>
                        </div>
                      ) : (
                        <div className="bannerBtn">
                          <button
                            className="contactBtn"
                            onClick={() => props.validateSubscribe("mt")}
                          >
                            Subscribe{" "}
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              className="chev-right"
                            />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {props.errorMsg && (
            <div className="col-12">
              <div className="error__alert">{props.errorMsg}</div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LandingMzaPage;
